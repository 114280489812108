$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * stickyHeader
	// *
	// *
    var $header = $(".js-header"),
        headerHeight = parseInt($header.outerHeight());

    $(window).on('resize', function() {
        $header.css("height", "").removeClass("sticky");
        headerHeight = parseInt($header.outerHeight());
    });

    $(window).on('scroll', function() {
        var objDistance = parseInt($header.offset().top),
            scrollDistance = $(window).scrollTop(),
            headerHeight = parseInt($header.outerHeight());

        $header.css("height", headerHeight + "px");

        if (scrollDistance > objDistance) {
            $header.addClass("sticky");
		}else {
			$header.removeClass("sticky");
		}
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * navigation
	// *
	// *
    $(".js-nav-btn").click(function(){
        $(".js-nav-btn").toggleClass("active");
        $(".js-nav").toggleClass("active");
        $(".js-sub-nav").toggleClass("active");
        $(".js-sub-nav-overlay").toggleClass("active");
        $("html, body").toggleClass("freeze");
        $(".js-nav-mobile").toggleClass("active");

        if ($(".js-nav-item.current").length) {
            $(".js-nav-item.current").trigger("click");
        }else {
            $(".js-nav-item:nth-child(1)").trigger("click");
        }
    });

    $(".js-sub-nav-overlay").click(function(){
        $(".js-nav-btn").trigger("click");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * subNavSlider
    // *
    // *
    var $subNavSlider = $(".js-sub-nav-slider");

    if ($subNavSlider.length){
        $subNavSlider.slick({
            fade: true,
            dots: false,
            arrows: false,
            waitForAnimate: false,
            swipe: false,
            touchMove: false,
            responsive: []
        });
    }

    // $(".js-nav-item").click(function(e) {
    //     e.preventDefault();
    //     $(this).addClass("active").siblings().removeClass("active");
    //     var navIndex = $(this).index();
    //     $subNavSlider.slick('slickGoTo', navIndex);
    // });

    $(".js-nav-item").click(function(e) {
        e.preventDefault();
        $(this).addClass("active").siblings().removeClass("active");
        var navIndex = $(this).index();
        $subNavSlider.slick('slickGoTo', navIndex);
    });

    // nav anchor
    $(".js-sub-nav-anchor").click(function(){
        $(".js-nav-btn").trigger("click");
    });

    var hash = window.location.hash;

    if (hash) {
        $('.js-sub-nav-anchor[href*="' + hash + '"]').parents("li").addClass("active");
        $(window).scrollTop($(window).scrollTop()+1);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * mobileNavigation
	// *
	// *
    $(".js-nav-mobile-button").click(function(){
        $(".js-nav-mobile-button").toggleClass("active");
        $(".js-nav-mobile").toggleClass("active");
        $('html, body').toggleClass('freeze');
    });

    $('.js-nav-mobile .parent').click(function(){
        $(this).siblings().removeClass("is-open").find("ul").slideUp();
		$(this).toggleClass('is-open').find('> ul').slideToggle();
	});

	// trigger active menü-parents
	$('.js-nav-mobile .parent.active > div > span').trigger("click");


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);

            if (this.hash == '#fuehrungsteam') {
                target = $(this.hash + "-stopjumpingonload");
            }

            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollToReal(target);
                return false;
            }
        }
    });

    function scrollToReal(element) {
        $('html').animate({
            scrollTop: element.offset().top - 92
        }, 1000);
    }






    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * scrollNext
	// *
	// *
    $(".js-scroll-next").click(function(){
        var $next = $(this).parents(".js-scroll-next-wrapper").next();
        scrollToReal($next);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    var $heroSlider = $(".js-hero-slider");

    if ($heroSlider.length){
        $heroSlider.slick({
            fade: true,
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 1500,
            waitForAnimate: false,
            appendDots: $(".js-hero-slider-dots-wrapper"),
            responsive: []
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * galleryCarousel
    // *
    // *
    var $galleryCarousel = $(".js-gallery-carousel");

    if ($galleryCarousel.length){
        $galleryCarousel.slick({
            fade: false,
            dots: true,
            arrows: false,
            centerMode: true,
            centerPadding: '500px',
            slidesToShow: 1,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        fade: false,
                        dots: true,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '300px',
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        fade: false,
                        dots: true,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '200px',
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        fade: false,
                        dots: true,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '100px',
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        fade: false,
                        dots: true,
                        arrows: false,
                        centerMode: true,
                        centerPadding: '30px',
                        slidesToShow: 1,
                    }
                },
            ]
        });

        // click on image to navigate
        $(".slick-slide").click(function(){
            var index = $(this).data("slick-index");
            $galleryCarousel.slick('slickGoTo', index);
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Nav Active when Element is in Viewpoint
    // *
    // *
    var $sections = $('[data-nav-section]'),
        $nav = $(".js-nav"),
        navHeight = 0; //$(".js-header").outerHeight();

    $(window).on('scroll', function () {
        var cur_pos = $(this).scrollTop() + 100;

        $sections.each(function() {
            var top = $(this).offset().top - navHeight, bottom = top + $(this).outerHeight();

            if (cur_pos >= top && cur_pos <= bottom) {
                $nav.find('li').removeClass('active');
                $sections.removeClass('active');
                $(this).addClass('active');
                var key = $(this).data("nav-section");
                $nav.find('a[href*="#' + key + '"]').parent('li').addClass('active');
            }
        });
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * toggle text
    // *
    // *
    $(".js-toggle-text-button").click(function(){
        $(this).toggleClass("active").parents(".js-toggle-text-wrapper").find(".js-toggle-text").slideToggle();
    });



    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        $(this).parents(".js-accordion-item").toggleClass("active").siblings().removeClass("active").find(".js-accordion-content").slideUp();
        $(this).parents(".js-accordion-item").find(".js-accordion-content").slideToggle();
    });

    $(".js-accordion-trigger-open").trigger("click");



    // * archive Button
    // *
    // *
    $(".js-archive-button").click(function(){
        $(this).fadeOut();
        $(".js-archive-item").slideDown();
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $slider = $(".js-slider");

    if ($slider.length){
        $slider.slick({
            fade: true,
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 1500,
            speed: 1500,
            waitForAnimate: false,
            responsive: []
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * textSlider
    // *
    // *
    var $slider = $(".js-text-slider");

    if ($slider.length){
        $slider.slick({
            fade: false,
            dots: false,
            arrows: true,
            prevArrow: $(".js-text-slider-nav-prev"),
            nextArrow: $(".js-text-slider-nav-next"),
            autoplay: false,
            waitForAnimate: true,
            adaptiveHeight: true,
            responsive: []
        });
    }


});


$(window).on("load", function (e) {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * newsTeaser
    // *
    // *
    var $newsTeaser = $(".js-news-teaser-wrapper");

    if ($newsTeaser.length) {
        var isoOptions = {
            itemSelector: '.js-news-teaser',
            masonry: {
                columnWidth: '.js-news-teaser-regular',
                gutter: 30,
            }
        };

        if ($(window).width() <= 1100) {
            if (newsTeaserIso) {
                $newsTeaser.isotope('destroy');
            }
        }else {
            var newsTeaserIso = $newsTeaser.isotope(isoOptions);
        }

        $(window).resize(function() {
            if ($( window ).width() <= 1100) {
                if (newsTeaserIso) {
                    $newsTeaser.isotope('destroy');
                }
            }else {
                var newsTeaserIso = $newsTeaser.isotope(isoOptions);
            }
        });

        // responsive project filter
        $(".js-news-teaser-filter-button-responsive").click(function(){
            $(this).toggleClass("active");
            $(".js-news-teaser-filter:not(:first-child)").slideToggle();
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * load more: News
    // *
    // *
    var $wrapper = $(".js-loadmore-wrapper"),
        $pagination = $(".js-loadmore-pagination"),
        $button = $(".js-loadmore-button");

    $button.click(function(){
        var url = $pagination.find("a.next").attr("href");
        var $nextLink = $pagination.find("a.next").removeClass("next").next("a").addClass("next");

        if (!$pagination.find("a.next").length) {
            $button.fadeOut();
        }

        $.get(url).done(function(data){
            var $newItems = $(data).find(".js-loadmore-wrapper .js-loadmore-item");

            if ($(window).width() >= 1100) {
                $newsTeaser.isotope('insert', $newItems).imagesLoaded().progress( function() {
                    $newsTeaser.isotope('layout');
                });
            }else {
                $newItems.appendTo($wrapper);
            }

        });
    });

    // mark next navigation page for load more button on init
    $pagination.find("a.active").next("a").addClass("next");

    // hide button if not enough news on init
    if ($pagination.find("a").length <= 1) {
        $button.hide();
    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * projectTeaser - Filter
    // *
    // *
    if ($(".js-project-teaser-wrapper").length) {

        // change nav filter active class
        $(".js-project-teaser-filter").click(function(){
            $(this).addClass("active").siblings().removeClass("active");

        });

        var mixer = mixitup('.js-project-teaser-wrapper', {
            selectors: {
                target: '.js-project-teaser'
            }
        });

        // responsive project filter
        $(".js-project-teaser-filter-button-responsive").click(function(){
            $(this).toggleClass("active");
            $(".js-project-teaser-filter:not(:first-child)").slideToggle();
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * logoWall
    // *
    // *
    var itemsToShow = 6;

    function changeLogos() {
        var randomOld = Math.floor(Math.random() * itemsToShow),
            randomNew = Math.floor(Math.random() * $(".js-logo-wall-item.hidden").length) + itemsToShow;

       var imageNew = $(".js-logo-wall-item:eq(" + randomNew + ")").html();
       var imageOld = $(".js-logo-wall-item:eq(" + randomOld + ")").html();

       var $wrapperVisible = $(".js-logo-wall-item:eq(" + randomOld + ")");
       var $wrapperHidden = $(".js-logo-wall-item:eq(" + randomNew + ")");

       $wrapperVisible.find("img").fadeOut(1000, function(){
           $wrapperVisible.html(imageNew).find("img").hide().fadeIn(1000);
       });
       $wrapperHidden.html(imageOld)
    }

    if ($(".js-logo-wall-item").length >= itemsToShow) {
        // hide logos
        $(".js-logo-wall-item:nth-child(" + itemsToShow + ")").nextAll().addClass("hidden");

        // start logoWall
        setInterval(changeLogos, 3000);
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Avoid jumping nav on font weight changes
    // *
    // *
    $(".js-nav-item").each(function(){
        var width = $(this).outerWidth() + 5;
        $(this).css("min-width", width + "px");
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * avoid default browsers anchor jumps and scroll to
    // *
    // *
    if (window.location.hash) {
        var hash = window.location.hash;

        if (hash == '#fuehrungsteam') {
            hash = window.location.hash + "-stopjumpingonload";
        }

        setTimeout(function(){
            $('html').animate({
                scrollTop: $(hash).offset().top - 92
            }, 1000);
        }, 400);

    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * image compare
    // *
    // *
    $(".js-imagecompare").each(function () {
        $(this).twentytwenty({
            no_overlay: true, //Do not show the overlay with before and after
        });
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * clone
    // *
    // *
    $(".js-clone-parent").each(function(){
        var originalElement = $(this).find(".js-clone-src"),
            clonedElement = originalElement.clone();
            clonedElement.appendTo($(this).find(".js-clone-target"));
    });

});
